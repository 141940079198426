import React from 'react';
import styled from 'styled-components';

export default ({ children }) => (
    <Container>
        <FooterBackground />
        <Footer>{children}</Footer>
    </Container>
)

const Container = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
`;

const FooterBackground = styled.div.attrs({ className: 'footer-background'})`
    mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 90%);
    min-height: 12vh;
    margin-bottom: -10px;
`;

const Footer = styled.div.attrs({ className: 'footer-background'})`
    padding-top: 16px;
    min-height: 12vh;
    padding-bottom: 1px;
`;
