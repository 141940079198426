import React from "react"
import styled from 'styled-components';
import OPTIONS from '../data/data.json'
import { Link, useHistory } from 'react-router-dom'
import useTitle from '../hooks/useTitle'
import listBar from '../assets/list-bar.jpg'
import StickyFooter from "./StickyFooter"

export default function ViewListScreen() {
  useTitle('List');
  const history = useHistory();
  
  React.useEffect(() => {
    goToTop()
  }, [])

  const goToTop = () => {
    window.scrollTo(0, 0)
  }

  const [filter, setFilter] = React.useState('')

  OPTIONS.sort((a, b) => a.name > b.name ? 1 : -1)

  const Filtered = OPTIONS.filter(option => option.tags.includes(filter))

  const getMovies = Filtered
    .map((item) => {
    if (item.type === 'Movie') {
      if (item.series !== '') {
        return (
          <div className="item row no-gutters" key={'item-' + item.id}>
            <h3 className="title fancy col">{item.series}: {item.name}</h3>
            <p className="duration col-auto">{item.duration} min.</p>
            <p className="desc col-12">{item.description}</p>
            <p className="genre col-12">Genre(s): {item.tags.replace(/,/g, ' / ')}</p>
          </div>
        )
      } else {
        return (
          <div className="item row no-gutters" key={'item-' + item.id}>
            <h3 className="title fancy col">{item.name}</h3>
            <p className="duration col-auto">{item.duration} min.</p>
            <p className="desc col-12">{item.description}</p>
            <p className="genre col-12">Genre(s): {item.tags.replace(/,/g, ' / ')}</p>
          </div>
        )
      }
    }
    return null
  })

  const getSpecials = Filtered
    .map((item) => {
    if (item.type === 'Special') {
      if (item.season !== '' && item.episode !== '') {
        return (
          <div className="item row no-gutters" key={'item-' + item.id}>
            <h3 className="title fancy col">{item.series}, S{item.season} E{item.episode}: {item.name}</h3>
            <p className="duration col-auto">{item.duration} min.</p>
            <p className="desc col-12">{item.description}</p>
            <p className="genre col-12">Genre(s): {item.tags.replace(/,/g, ' / ')}</p>
          </div>
        )
      } else if (item.series !== '') {
        return (
          <div className="item row no-gutters" key={'item-' + item.id}>
            <h3 className="title fancy col">{item.series}: {item.name}</h3>
            <p className="duration col-auto">{item.duration} min.</p>
            <p className="desc col-12">{item.description}</p>
            <p className="genre col-12">Genre(s): {item.tags.replace(/,/g, ' / ')}</p>
          </div>
        )
      } else {
        return (
          <div className="item row no-gutters" key={'item-' + item.id}>
            <h3 className="title fancy col">{item.name}</h3>
            <p className="duration col-auto">{item.duration} min.</p>
            <p className="desc col-12">{item.description}</p>
            <p className="genre col-12">Genre(s): {item.tags.replace(/,/g, ' / ')}</p>
          </div>
        )
      }
    }
    return null
  })

  const getShows = Filtered
    .map((item) => {
    if (item.type === 'Series') {
      if (item.season !== '' && item.episode !== '') {
        return (
          <div className="item row no-gutters" key={'item-' + item.id}>
            <h3 className="title fancy col">{item.series}, S{item.season} E{item.episode}: {item.name}</h3>
            <p className="duration col-auto">{item.duration} min.</p>
            <p className="desc col-12">{item.description}</p>
            <p className="genre col-12">Genre(s): {item.tags.replace(/,/g, ' / ')}</p>
          </div>
        )
      } else if (item.series !== '') {
        return (
          <div className="item row no-gutters" key={'item-' + item.id}>
            <h3 className="title fancy col">{item.series}: {item.name}</h3>
            <p className="duration col-auto">{item.duration} min.</p>
            <p className="desc col-12">{item.description}</p>
            <p className="genre col-12">Genre(s): {item.tags.replace(/,/g, ' / ')}</p>
          </div>
        )
      } else {
        return (
          <div className="item row no-gutters" key={'item-' + item.id}>
            <h3 className="title fancy col">{item.name}</h3>
            <p className="duration col-auto">{item.duration} min.</p>
            <p className="desc col-12">{item.description}</p>
            <p className="genre col-12">Genre(s): {item.tags.replace(/,/g, ' / ')}</p>
          </div>
        )
      }
    }
    return null
  })

  return (
    <>
    <header id="header" className="row align-items-center justify-content-center no-gutters">
      <div className="col-12 text-center">
        <h2 className="fancy">Full Holiday List</h2>
        <div id="filter">
          <img src={listBar} className="topList" alt="Filter Bars" />
          <ul>
            <li className={`filter ${filter === '' ? 'active' : ''}`} onClick={() => setFilter('')}>A-Z</li>
            <li className={`filter ${filter === 'Action/Adventure' ? 'active' : ''}`} onClick={() => setFilter('Action/Adventure')}>Action Adventure</li>
            <li className={`filter ${filter === 'Comedy' ? 'active' : ''}`} onClick={() => setFilter('Comedy')}>Comedy</li>
            <li className={`filter ${filter === 'Animated/Cartoon' ? 'active' : ''}`} onClick={() => setFilter('Animated/Cartoon')}>Animated/Cartoon</li>
            <li className={`filter ${filter === 'Romance/Drama' ? 'active' : ''}`} onClick={() => setFilter('Romance/Drama')}>Romance/Drama</li>
            <li className={`filter ${filter === 'Misc' ? 'active' : ''}`} onClick={() => setFilter('Misc')}>Misc</li>
          </ul>
          <img src={listBar} className="bottomList" alt="Filter Bars" />
        </div>
      </div>
    </header>
    <div id="FullList" className="main row align-items-center justify-content-center">
      <div className="col-12 col-md-11">
        <div className="row align-items-start justify-content-center">
          <div className="col-12">
            <h3>Movies</h3>
            {getMovies}  
            
            <h3 className="mt-5">Specials</h3>
            {getSpecials}

            <h3 className="mt-5">Shows</h3>
            {getShows}  
            <Spacer />
          </div>
          <StickyFooter>
            <div className="col-12 text-center">
              <Link
                className="btn btn-small mx-3 mb-5"
                to={`/Schedule${history.location.search}`}
              >
                Back
              </Link>
            </div>
          </StickyFooter>
        </div>
      </div>
    </div>
    </>
  )
}

const Spacer = styled.div`
  height: 12rem;
`;