import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router";

export default function CalculatorScreen() {
  const history = useHistory();

  const [genreInteract, setGenreInteract] = useState(false);
  const [hoursInteract, setHoursInteract] = useState(false);
  const [aggressiveInteract, setAggressiveInteract] = useState(false);

  const [rating, setRating] = useState("");

  const [action, setAction] = useState(false);
  const [comedy, setComedy] = useState(false);
  const [animated, setAnimated] = useState(false);
  const [drama, setDrama] = useState(false);
  const [misc, setMisc] = useState(false);

  const [commute, setCommute] = useState(false);
  const [afterWork, setAfterWork] = useState(false);
  const [weekend, setWeekend] = useState(false);
  const [kidsAsleep, setKidsAsleep] = useState(false);

  const [aggressive, setAggressive] = useState("");

  useEffect(() => {
    document.title = "Calculator";
    window.scrollTo(0, 0);
  }, []);

  const onChangeChildren = () => {
    setRating("children");
  };
  const onChangeFamily = () => {
    setRating("family");
  };
  const onChangeMature = () => {
    setRating("mature");
  };

  const onChangeAction = () => {
    setAction(!action);
  };
  const onChangeComedy = () => {
    setComedy(!comedy);
  };
  const onChangeAnimated = () => {
    setAnimated(!animated);
  };
  const onChangeDrama = () => {
    setDrama(!drama);
  };
  const onChangeMisc = () => {
    setMisc(!misc);
  };

  const onChangeCommute = () => {
    setCommute(!commute);
  };
  const onChangeAfterWork = () => {
    setAfterWork(!afterWork);
  };
  const onChangeWeekend = () => {
    setWeekend(!weekend);
  };
  const onChangeKidsAsleep = () => {
    setKidsAsleep(!kidsAsleep);
  };

  const onWhenChange = (e) => {
    setHoursInteract(e.target.value);
  };

  const onAggressiveChange = (e) => {
    setAggressive(e.target.value);
  };

  const resetCalculator = () => {
    setRating("");
    setAction(false);
    setComedy(false);
    setAnimated(false);
    setDrama(false);
    setMisc(false);
    setCommute(false);
    setAfterWork(false);
    setWeekend(false);
    setKidsAsleep(false);
    setAggressive("");
    setGenreInteract(false);
    setHoursInteract(false);
    setAggressiveInteract(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    const today = `${year}-${month}-${day}`;

    let ratings = "";
    if (rating === "children") {
      ratings += "&rating=children";
    }

    if (rating === "family") {
      ratings += "&rating=family";
    }

    if (rating === "mature") {
      ratings += "&rating=mature";
    }

    let genres = "";
    if (action) {
      genres += `&genre=action`;
    }

    if (comedy) {
      genres += `&genre=comedy`;
    }

    if (animated) {
      genres += `&genre=animated`;
    }

    if (drama) {
      genres += `&genre=drama`;
    }

    if (misc) {
      genres += `&genre=misc`;
    }

    let hours = "";
    if (commute) {
      hours += `&hours=commute`;
    }

    if (afterWork) {
      hours += `&hours=afterWork`;
    }

    if (weekend) {
      hours += `&hours=weekend`;
    }

    if (kidsAsleep) {
      hours += `&hours=kidsAsleep`;
    }

    const query = `?${ratings}${genres}${hours}&aggressive=${aggressive}&today=${today}`;
    history.push(`/Schedule${query}`);
  };

  const handleGenreInteract = () => {
    setGenreInteract(true);
  };
  const handleHoursInteract = () => {
    setHoursInteract(true);
  };
  const handleAggressiveInteract = () => {
    setAggressiveInteract(true);
  };

  const WhatRating = ({ handleGenreInteract }) => {
    return (
      <div className="row text-center">
        <div className="col-12 mb-3">
          <h3 className="fancy">Who's watching?</h3>
          <ul>
            <li>
              <label className="cm-radio">
                <span className="cm-radio_input">
                  <input
                    type="radio"
                    value="children"
                    name="rating"
                    checked={rating === "children"}
                    onChange={onChangeChildren}
                    onClick={handleGenreInteract}
                  />
                  <span className="cm-radio_control">
                    <span className="cm-radio_dot"></span>
                  </span>
                </span>
                <span className="cm-radio_label">Distract the Kiddos</span>
              </label>
            </li>

            <li>
              <label className="cm-radio">
                <span className="cm-radio_input">
                  <input
                    type="radio"
                    value="family"
                    name="rating"
                    className="rating cm-toggle"
                    checked={rating === "family"}
                    onChange={onChangeFamily}
                    onClick={handleGenreInteract}
                  />
                  <span className="cm-radio_control">
                    <span className="cm-radio_dot"></span>
                  </span>
                </span>
                <span className="cm-radio_label">Family Binge Time</span>
              </label>
            </li>

            <li>
              <label className="cm-radio">
                <span className="cm-radio_input">
                  <input
                    type="radio"
                    value="mature"
                    name="rating"
                    className="rating cm-toggle"
                    checked={rating === "mature"}
                    onChange={onChangeMature}
                    onClick={handleGenreInteract}
                  />
                  <span className="cm-radio_control">
                    <span className="cm-radio_dot"></span>
                  </span>
                </span>
                <span className="cm-radio_label">Grown-Ups Only</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const genreOn = genreInteract;
  let genreClass = "row text-center";
  if (genreOn) {
    genreClass += " active";
  }

  const WhatGenre = ({ handleHoursInteract }) => {
    return (
      <div id="genre" className={genreClass}>
        <div className="col-12 mb-3">
          <h3 className="fancy">What genres do you enjoy?</h3>
          <ul className="ul-genre">
            <li>
              <label>
                <div>
                  <input
                    type="checkbox"
                    value="action"
                    name="genre"
                    className="genre cm-toggle"
                    checked={action}
                    onChange={onChangeAction}
                    disabled={!genreOn}
                    onClick={handleHoursInteract}
                  />
                </div>
                <span>Action/Adventure</span>
              </label>
            </li>

            <li>
              <label>
                <div>
                  <input
                    type="checkbox"
                    value="comedy"
                    name="genre"
                    className="genre cm-toggle"
                    checked={comedy}
                    onChange={onChangeComedy}
                    disabled={!genreOn}
                    onClick={handleHoursInteract}
                  />
                </div>
                <span>Comedy</span>
              </label>
            </li>

            <li>
              <label>
                <div>
                  <input
                    type="checkbox"
                    value="animated"
                    name="genre"
                    className="genre cm-toggle"
                    checked={animated}
                    onChange={onChangeAnimated}
                    disabled={!genreOn}
                    onClick={handleHoursInteract}
                  />
                </div>
                <span>Animated/Cartoon</span>
              </label>
            </li>

            <li>
              <label>
                <div>
                  <input
                    type="checkbox"
                    value="drama"
                    name="genre"
                    className="genre cm-toggle"
                    checked={drama}
                    onChange={onChangeDrama}
                    disabled={!genreOn}
                    onClick={handleHoursInteract}
                  />
                </div>
                <span>Romance & Drama</span>
              </label>
            </li>

            <li>
              <label>
                <div>
                  <input
                    type="checkbox"
                    value="misc"
                    name="genre"
                    className="genre cm-toggle"
                    checked={misc}
                    onChange={onChangeMisc}
                    disabled={!genreOn}
                    onClick={handleHoursInteract}
                  />
                </div>
                <span>Mix It Up</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const hoursOn = hoursInteract;
  let hoursClass = "row text-center";
  if (hoursOn) {
    hoursClass += " active";
  }

  const WhenWatch = ({ handleAggressiveInteract }) => {
    return (
      <div id="hours" className={hoursClass}>
        <div className="col-12 mb-3">
          <h3 className="fancy">When do you plan on watching?</h3>
          <ul className="ul-when">
            <li className="li-when">
              <label>
                <div>
                  <input
                    type="checkbox"
                    value="commute"
                    name="hours"
                    className="hours cm-toggle"
                    checked={commute}
                    onChange={onChangeCommute}
                    disabled={!hoursOn}
                    onClick={handleAggressiveInteract}
                  />
                </div>
                <span>On My Commute</span>
              </label>
            </li>

            <li>
              <label>
                <div>
                  <input
                    type="checkbox"
                    value="afterWork"
                    name="hours"
                    className="hours cm-toggle"
                    checked={afterWork}
                    onChange={onChangeAfterWork}
                    disabled={!hoursOn}
                    onClick={handleAggressiveInteract}
                  />
                </div>
                <span>After Work/School</span>
              </label>
            </li>

            <li>
              <label>
                <div>
                  <input
                    type="checkbox"
                    value="weekend"
                    name="hours"
                    className="hours cm-toggle"
                    checked={weekend}
                    onChange={onChangeWeekend}
                    disabled={!hoursOn}
                    onClick={handleAggressiveInteract}
                  />
                </div>
                <span>Weekend Binge Fest</span>
              </label>
            </li>

            <li>
              <label>
                <div>
                  <input
                    type="checkbox"
                    value="kidsAsleep"
                    name="hours"
                    className="hours cm-toggle"
                    checked={kidsAsleep}
                    onChange={onChangeKidsAsleep}
                    disabled={!hoursOn}
                    onClick={handleAggressiveInteract}
                  />
                </div>
                <span>When The Kids Sleep</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const aggressiveOn = aggressiveInteract;
  let aggressiveClass = "row text-center";
  if (aggressiveOn) {
    aggressiveClass += " active";
  }

  const HowAggressive = ({ match }) => {
    return (
      <div id="aggressive" className={aggressiveClass}>
        <div className="col-12 mb-3">
          <h3 className="fancy">How aggressively do you want to binge?</h3>
          <div className="aggroLine"></div>
          <ul className="aggressive">
            <li>
              <label className="cm-radio">
                <span className="cm-radio_input">
                  <input
                    type="radio"
                    value="1"
                    name="aggressive"
                    checked={aggressive === "1"}
                    onChange={onAggressiveChange}
                    disabled={!aggressiveOn}
                  />
                  <span className="cm-radio_control">
                    <span className="cm-radio_dot"></span>
                  </span>
                </span>
                <span className="cm-radio_label">Take it Easy</span>
              </label>
            </li>

            <li>
              <label className="cm-radio">
                <span className="cm-radio_input">
                  <input
                    type="radio"
                    value="2"
                    name="aggressive"
                    checked={aggressive === "2"}
                    onChange={onAggressiveChange}
                    disabled={!aggressiveOn}
                  />
                  <span className="cm-radio_control">
                    <span className="cm-radio_dot"></span>
                  </span>
                </span>
                <span className="cm-radio_label">&nbsp;</span>
              </label>
            </li>

            <li>
              <label className="cm-radio">
                <span className="cm-radio_input">
                  <input
                    type="radio"
                    value="3"
                    name="aggressive"
                    checked={aggressive === "3"}
                    onChange={onAggressiveChange}
                    disabled={!aggressiveOn}
                  />
                  <span className="cm-radio_control">
                    <span className="cm-radio_dot"></span>
                  </span>
                </span>
                <span className="cm-radio_label">&nbsp;</span>
              </label>
            </li>

            <li>
              <label className="cm-radio">
                <span className="cm-radio_input">
                  <input
                    type="radio"
                    value="4"
                    name="aggressive"
                    checked={aggressive === "4"}
                    onChange={onAggressiveChange}
                    disabled={!aggressiveOn}
                  />
                  <span className="cm-radio_control">
                    <span className="cm-radio_dot"></span>
                  </span>
                </span>
                <span className="cm-radio_label">&nbsp;</span>
              </label>
            </li>

            <li>
              <label className="cm-radio">
                <span className="cm-radio_input">
                  <input
                    type="radio"
                    value="5"
                    name="aggressive"
                    checked={aggressive === "5"}
                    onChange={onAggressiveChange}
                    disabled={!aggressiveOn}
                  />
                  <span className="cm-radio_control">
                    <span className="cm-radio_dot"></span>
                  </span>
                </span>
                <span className="cm-radio_label">Let's Go!</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const enabled =
    rating &&
    (action || comedy || animated || drama || misc) &&
    (weekend || commute || afterWork || kidsAsleep) &&
    aggressive;

  const handleTapCalculate = () => {
    ReactGA.event({
      category: "Interaction",
      action: "Binge Calculate",
    });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div
          id="Calculator"
          className="main row align-items-center justify-content-center"
        >
          <div className="col-12 col-md-10">
            <WhatRating handleGenreInteract={handleGenreInteract} />
            <WhatGenre handleHoursInteract={handleHoursInteract} />
            <WhenWatch handleAggressiveInteract={handleAggressiveInteract} />
            <HowAggressive />
          </div>
        </div>
        <footer
          id="footer"
          className="row align-items-center justify-content-center"
        >
          <div className="col-12 text-center">
            <button
              type="submit"
              id="getSchedule"
              className="btn btn-small mx-3 fancy-black"
              disabled={!enabled}
              onClick={handleTapCalculate}
            >
              Calculate
            </button>
            <button
              type="button"
              className="btn-link reset"
              onClick={resetCalculator}
            >
              RESET
            </button>
          </div>
        </footer>
      </form>
    </>
  );
}
