import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import shuffle from "shuffle-array";
import useTitle from "../hooks/useTitle";
import useShortURL from "../hooks/useShortURL";
import StickyFooter from "./StickyFooter";
import ReactGA from "react-ga";

import OPTIONS from "../data/data.json";
import TIPS from "../data/tips.json";
import tipBar from "../assets/tip-bar.jpg";

const { REACT_APP_MODE } = process.env;

const GENRES = {
  action: "Action/Adventure",
  comedy: "Comedy",
  animated: "Animated/Cartoon",
  drama: "Romance/Drama",
  misc: "Misc",
};
const AVAIL_HOURS = {
  weekend: 6,
  kidsAsleep: 3,
  afterWork: 3,
  commute: 2,
};
const AGGRESSIVE = {
  1: 0.25,
  2: 0.63,
  3: 1,
  4: 1.37,
  5: 1.75,
};

export default function ScheduleScreen() {
  useTitle("Schedule");
  const { createShortUrl } = useShortURL();
  const history = useHistory();

  useEffect(() => {
    goToTop();
  }, []);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const url = new URL(`http://dummy.com/${history.location.search}`);
  const rating = url.searchParams.getAll("rating");
  const genre = url.searchParams.getAll("genre");
  const hours = url.searchParams.getAll("hours");
  const aggressiveLevel = url.searchParams.get("aggressive");
  const scheduleDate = url.searchParams.get("today");

  let ratings = [];
  if (rating.includes("children")) {
    ratings.push("children");
  }
  if (rating.includes("family")) {
    ratings.push("family");
  }
  if (rating.includes("mature")) {
    ratings.push("mature");
  }

  let genres = [];
  if (genre.includes("action")) {
    genres.push(GENRES["action"]);
  }
  if (genre.includes("comedy")) {
    genres.push(GENRES["comedy"]);
  }
  if (genre.includes("animated")) {
    genres.push(GENRES["animated"]);
  }
  if (genre.includes("drama")) {
    genres.push(GENRES["drama"]);
  }
  if (genre.includes("misc")) {
    genres.push(GENRES["misc"]);
  }

  let hoursAll = [];
  if (hours.includes("weekend")) {
    hoursAll.push(AVAIL_HOURS["weekend"]);
  }
  if (hours.includes("kidsAsleep")) {
    hoursAll.push(AVAIL_HOURS["kidsAsleep"]);
  }
  if (hours.includes("afterWork")) {
    hoursAll.push(AVAIL_HOURS["afterWork"]);
  }
  if (hours.includes("commute")) {
    hoursAll.push(AVAIL_HOURS["commute"]);
  }

  const redirectToCalc = () => {
    history.push("/Calculator");
    return null;
  };

  // Check For Parameters
  if (ratings.length === 0) {
    return redirectToCalc();
  }
  if (genres.length === 0) {
    return redirectToCalc();
  }
  if (hoursAll.length === 0) {
    return redirectToCalc();
  }
  if (aggressiveLevel === "") {
    return redirectToCalc();
  }
  if (scheduleDate === "") {
    return redirectToCalc();
  }

  const showList = OPTIONS.filter((option) => {
    return option.type === "Series";
  })
    .filter((option) => {
      let match = false;
      ratings.forEach(function (rating_array) {
        if (option.rating.includes(rating_array)) {
          match = true;
        }
      });
      return match;
    })
    .filter((option) => {
      let match = false;
      genres.forEach(function (genre_array) {
        if (option.tags.includes(genre_array)) {
          match = true;
        }
      });
      return match;
    });

  const movieList = OPTIONS.filter((option) => {
    return option.type === "Movie" || option.type === "Special";
  })
    .filter((option) => {
      let match = false;
      ratings.forEach(function (rating_array) {
        if (option.rating.includes(rating_array)) {
          match = true;
        }
      });
      return match;
    })
    .filter((option) => {
      let match = false;
      genres.forEach(function (genre_array) {
        if (option.tags.includes(genre_array)) {
          match = true;
        }
      });
      return match;
    });

  const showTimes = showList.map((option) => {
    return option.duration;
  });

  const movieTimes = movieList.map((option) => {
    return option.duration;
  });

  const TotalTime = () => {
    let totalShow = sum(showTimes);
    let totalMovie = sum(movieTimes);
    let totalMinutes = totalShow + totalMovie;
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    return (
      <>
        {hours} HOURS {minutes} MINUTES
      </>
    );
  };

  const showRows = showList.length;
  const movieRows = movieList.length;

  const makeSchedule = () => {
    let remainingItems = [...showList, ...movieList];
    let maxTime = Math.max.apply(
      Math,
      remainingItems.map(function (o) {
        return o.duration;
      })
    );
    let weekdayMinutes = 0;
    if (hours.includes("kidsAsleep")) {
      weekdayMinutes += AVAIL_HOURS["kidsAsleep"] * 60;
    }
    if (hours.includes("afterWork")) {
      weekdayMinutes += AVAIL_HOURS["afterWork"] * 60;
    }
    if (hours.includes("commute")) {
      weekdayMinutes += AVAIL_HOURS["commute"] * 60;
    }

    let weekendMinutes = 0;
    if (hours.includes("weekend")) {
      weekendMinutes += AVAIL_HOURS["weekend"] * 60;
    }
    if (hours.includes("kidsAsleep")) {
      weekendMinutes += AVAIL_HOURS["kidsAsleep"] * 60;
    }

    let aggressive = AGGRESSIVE[aggressiveLevel];
    weekdayMinutes = Math.round(weekdayMinutes * aggressive);
    if (weekdayMinutes > 0 && weekdayMinutes < maxTime) {
      weekdayMinutes = maxTime;
    }
    weekendMinutes = Math.round(weekendMinutes * aggressive);
    if (weekendMinutes > 0 && weekendMinutes < maxTime) {
      weekendMinutes = maxTime;
    }

    let minutesAvailablePerDay = [
      weekendMinutes,
      weekdayMinutes,
      weekdayMinutes,
      weekdayMinutes,
      weekdayMinutes,
      weekdayMinutes,
      weekendMinutes,
    ];

    const tips = [...TIPS];
    shuffle(tips);

    const schedule = [];
    let sheduledObject;
    let dayCount = 0;
    let watchDayCount = 0;
    while (remainingItems.length > 0) {
      dayCount++;
      // TODO: start with date from parameter
      let preciseDate = new Date(scheduleDate);
      let date = new Date(
        preciseDate.getFullYear(),
        preciseDate.getMonth(),
        preciseDate.getDate() + 1
      );
      // let date = new Date()
      date.setDate(date.getDate() + dayCount);
      let dayOfWeek = date.getDay();
      let watchableItemsInADay = [];
      let minutesLeft = minutesAvailablePerDay[dayOfWeek];
      while (remainingItems.length > 0) {
        // attempt to insert time sensitive item
        while (true) {
          let itemsProcessed = 0;
          let totalItems = remainingItems.length;
          for (let i = 0; i < remainingItems.length; i++) {
            itemsProcessed++;
            let nextItem = remainingItems[i];
            if (nextItem.streamStart && nextItem.streamEnd) {
              const startDay = Date.parse(nextItem.streamStart);
              const endDay = Date.parse(nextItem.streamEnd);
              const isAvailable = endDay >= date && date >= startDay;
              if (isAvailable && nextItem.duration <= minutesLeft) {
                minutesLeft -= nextItem.duration;
                watchableItemsInADay.push(nextItem);
                remainingItems.splice(i, 1);
                break;
              }
            }
          }
          if (itemsProcessed === totalItems) break;
        }

        if (remainingItems.length === 0) break;

        let nextItem = remainingItems[0];
        if (nextItem.duration <= minutesLeft) {
          minutesLeft -= nextItem.duration;
          watchableItemsInADay.push(remainingItems.shift());
        } else {
          break;
        }
      }

      if (watchableItemsInADay.length > 0) {
        sheduledObject = {
          type: "day",
          date: date,
          itemsForThisDay: watchableItemsInADay,
        };
        schedule.push(sheduledObject);

        watchDayCount++;

        if (watchDayCount % 4 === 1 && tips.length > 0) {
          sheduledObject = {
            type: "tip",
            tip: tips.pop(),
          };
          schedule.push(sheduledObject);
        }
      }
    }
    return schedule;
  };

  const renderTip = (tipData, index) => (
    <div className="col-12 tip" key={"item-" + index}>
      <div className="row align-items-start justify-content-center">
        <img src={tipBar} className="topBar" alt="Tip Bars" />
        <div className="col-12 col-md-9 py-3">
          <h3
            className="fancy mb-0"
            dangerouslySetInnerHTML={{ __html: tipData.tip.title }}
          />
          <p dangerouslySetInnerHTML={{ __html: tipData.tip.content }} />
        </div>
        <img src={tipBar} className="bottomBar" alt="Tip Bars" />
      </div>
    </div>
  );

  const renderDay = (dayData, index) => {
    let viewingTotal = dayData.itemsForThisDay.length;
    let viewingTime = dayData.itemsForThisDay.map((item) => {
      return item.duration;
    });
    let viewingTotalMinutes = sum(viewingTime);
    let viewingHours = Math.floor(viewingTotalMinutes / 60);
    let viewingMinutes = viewingTotalMinutes % 60;

    const thingsToWatch = () => {
      if (viewingTotal === 1) {
        return (
          <p className="time">
            {viewingTotal} Thing to watch{" "}
            <small>
              ({viewingHours} hours {viewingMinutes} minutes)
            </small>
          </p>
        );
      } else {
        return (
          <p className="time">
            {viewingTotal} Things to watch{" "}
            <small>
              ({viewingHours} hours {viewingMinutes} minutes)
            </small>
          </p>
        );
      }
    };

    return (
      <div className="col-12 col-md-9 day" key={"item-" + index}>
        <div className="row align-items-start justify-content-center">
          <div className="col-12 col-sm-auto">
            <h3 className="date">
              {dayData.date.toString().split(" ")[0]}
              <br />
              <small>
                {dayData.date.getMonth() + 1}/{dayData.date.getDate()}
              </small>
            </h3>
          </div>
          <div className="col-12 col-sm">
            {thingsToWatch()}
            {dayData.itemsForThisDay.map((item) => {
              if (item.season !== "" && item.episode !== "") {
                return (
                  <p key={"item-" + item.id} className="fancy">
                    - {item.series}, S{item.season} E{item.episode}: {item.name}
                  </p>
                );
              } else if (item.series !== "") {
                return (
                  <p key={"item-" + item.id} className="fancy">
                    - {item.series}: {item.name}
                  </p>
                );
              } else {
                return (
                  <p key={"item-" + item.id} className="fancy">
                    - {item.name}
                  </p>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderScheduledItem = (itemData, index) => {
    if (itemData.type === "tip") {
      return renderTip(itemData, index);
    } else if (itemData.type === "day") {
      return renderDay(itemData, index);
    }
  };

  const schedule = makeSchedule();

  const getList = async () => {
    const shortUrl = await createShortUrl(window.location.href);
    sessionStorage.setItem("shortUrl", shortUrl);

    if (REACT_APP_MODE === "kiosk") {
      const qrCodeUrl = await createShortUrl(
        window.location.href,
        "windowbinge.fanyourway.com"
      );
      sessionStorage.setItem("qrCodeUrl", qrCodeUrl);
    }

    history.push(`/GetList${history.location.search}`);

    ReactGA.event({
      category: "Interaction",
      action: "Binge Send Schedule",
    });
  };

  const handleTapBrowse = () => {
    ReactGA.event({
      category: "Interaction",
      action: "Binge Browse",
    });
  };

  return (
    <>
      <div
        id="Schedule"
        className="row align-items-start justify-content-center no-gutters"
      >
        <div className="infoTotals col-12 text-center">
          Your customized HBO Max watch list: {movieRows} Movies + {showRows}{" "}
          Shows = <TotalTime />
        </div>

        <div className="col-12">
          <div className="row align-items-center justify-content-center no-gutters">
            {schedule.map((scheduledObject, index) =>
              renderScheduledItem(scheduledObject, index)
            )}
            {schedule.length === 0 && (
              <>
                <div className="text-center">
                  <h3>Sorry! The selected options returned no results.</h3>
                  <p>
                    Click "Recalculate" below to try selecting additional
                    options.
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Spacer />
      <StickyFooter
        id="footer"
        className="row align-items-center justify-content-center"
      >
        <div className="controls col-12 text-center">
          <div className="row align-items-center justify-content-center">
            <div className="recalculate col-6 col-md-auto order-2 order-md-1">
              <Link className="btn-link" to={"/Calculator"}>
                ReCalculate
              </Link>
            </div>
            <div className="send text-center col-12 col-md-auto order-1 order-md-6">
              <button
                type="button"
                className="btn btn-small fancy-black"
                onClick={getList}
              >
                Send My Schedule
              </button>
            </div>
            <div className="browse col-6 col-md-auto order-12">
              <Link
                className="btn-link"
                to={`/ViewList${history.location.search}`}
                onClick={handleTapBrowse}
              >
                Browse Full List
              </Link>
            </div>
          </div>
        </div>
      </StickyFooter>
    </>
  );
}

function sum(obj) {
  var sum = 0;
  for (var el in obj) {
    if (obj.hasOwnProperty(el)) {
      sum += parseFloat(obj[el]);
    }
  }
  return sum;
}

const Spacer = styled.div`
  height: 12rem;
`;
