import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import useTitle from "../hooks/useTitle";

export default function CTAScreen() {
  useTitle("Home");

  React.useEffect(() => {
    goToTop();
  }, []);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleTapStart = () => {
    ReactGA.event({
      category: "Interaction",
      action: "Binge Start",
    });
    return "/Calculator";
  };

  return (
    <>
      <div id="CTA" className="main row align-items-end justify-content-center">
        <div className="col-12 col-md-9">
          <div className="row text-center">
            <div className="col-12 animating-on-off animation-delay-1">
              <h3 className="mb-5">
                Everyone can get cozy with the HBO Max holiday binge watch list.
              </h3>
            </div>
            <div className="col-12 mb-3 animating-on-off animation-delay-2">
              <Link
                to={handleTapStart}
                className="btn btn-large pulsing fancy-black"
              >
                Get Started!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
