import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import useShortURL from "../hooks/useShortURL";

export default ({ match }) => {
    const history = useHistory();
    const { getLongUrl } = useShortURL();

    useEffect(() => {    
        async function handleGetLongUrl() {
            const { shortUrlId } = match.params;
            const longUrl = await getLongUrl(shortUrlId);
            if (!longUrl) {
                history.push('/')
            } else {
                window.location.href = longUrl;
            }
        }

        handleGetLongUrl();
    });

    return null;
}