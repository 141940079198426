import { useState } from "react";
import axios from "axios";

const { REACT_APP_MODE } = process.env;

const WEB_DOMAIN = "https://binge.fanyourway.com";

export default () => {
  const [shortUrl, setShortUrl] = useState();

  const createShortUrl = async (longUrl, substituteDomain) => {
    let lUrl = longUrl;
    if (REACT_APP_MODE === "kiosk") {
      const { protocol, hostname, port } = window.location;
      let truncatedUrl = lUrl
        .replace(`${protocol}//`, "")
        .replace(hostname, "");
      truncatedUrl = port ? truncatedUrl.replace(`:${port}`, "") : truncatedUrl;
      console.log(truncatedUrl);
      lUrl = `${WEB_DOMAIN}${truncatedUrl}`;
    }

    const shortUrlId = await createShortUrlId(lUrl);
    if (shortUrlId) {
      const { protocol, hostname, port } = window.location;

      let domain = "";
      if (substituteDomain) {
        domain = `https://${substituteDomain}/`;
      } else if (REACT_APP_MODE === "kiosk") {
        domain = WEB_DOMAIN;
      } else {
        domain = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
      }

      const shortUrl = `${domain}#/u/${shortUrlId}`;
      setShortUrl(shortUrl);
      return shortUrl;
    } else {
      setShortUrl(longUrl);
      return longUrl;
    }
  };

  const createShortUrlId = async (longUrl) => {
    try {
      const response = await axios.post(
        "https://api.fanyourway.com/url-shortener/url/",
        { longUrl }
      );
      if (response.status === 200) {
        if (response.data && response.data.urlId) {
          return response.data.urlId;
        }

        console.error("Couldn't get short URL. Unexpected response.", response);
        return;
      }

      console.error(
        "Couldn't get short URL. Response status is not 200.",
        response.status
      );
      return;
    } catch (error) {
      console.error("Couldn't get short URL.", error);
      return;
    }
  };

  const getLongUrl = async (shortUrlId) => {
    try {
      const response = await axios.get(
        `https://api.fanyourway.com/url-shortener/url/${shortUrlId}`
      );
      if (response.status === 200) {
        if (response.data && response.data.longUrl) {
          return response.data.longUrl;
        }

        console.error("Couldn't get long URL. Unexpected response.", response);
        return;
      }

      console.error(
        "Couldn't get long URL. Response status is not 200.",
        response.status
      );
      return;
    } catch (error) {
      console.error("Couldn't get long URL.", error);
      return;
    }
  };

  return { shortUrl, createShortUrl, getLongUrl };
};
