import React from "react";
import { HashRouter as Router, Route, useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import ReactGA from "react-ga";

import * as Styled from "./App.styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./font/font.css";
import footer from "./assets/footer.png";

import CTAScreen from "./components/CTA";
import CalculatorScreen from "./components/Calculator";
import ScheduleScreen from "./components/Schedule";
import ViewListScreen from "./components/ViewList";
import GetListOnlineScreen from "./components/GetListOnline";
import EmailScreen from "./components/Email";
import FinishScreen from "./components/Finish";
import ShortURLHandler from "./components/ShortURLHandler";
import attachUIConsole from "./util/attachUIConsole";
import { GetClientName } from "./util/sysinfo";

const { REACT_APP_MODE } = process.env;
const GA_ANALYTICS_ID = "UA-36950697-24";
const IDLE_TIMEOUT_S = 90;
const DEBUG_ON_SCREEN = false;
const DEBUG_ANALYTICS = false;

const Header = ({ shouldAnimate = false }) => {
  return (
    <>
      <header
        id="header"
        className="row align-items-center justify-content-center no-gutters"
      >
        <div className="col-12 text-center">
          <div className={shouldAnimate ? "animating-on-off" : ""}>
            <h1 className="fancy">Holiday</h1>
            <h2 className="fancy-black">Binge Calculator</h2>
          </div>
        </div>
      </header>
    </>
  );
};

const Footer = () => {
  return (
    <footer id="footer" className="row align-items-end justify-content-center">
      <div className="col-12 text-center">
        <img src={footer} alt="Footer" />
        <p>
          <small>
            Access HBO Max only through HBO Max app or hbomax.com with your
            AT&amp;T log-in credentials. Other conditions and restrictions
            apply. See rep for details. HBO Max is only accessible in the U.S.
            and certain U.S. territories where a high-speed broadband connection
            is available. HBO MAX is used under license.
          </small>
        </p>
        <p>
          <small>
            &copy; 2021 AT&amp;T Intellectual Property. All rights reserved.
            AT&amp;T and the Globe logo are registered trademarks of AT&amp;T
            Intellectual Property. All other marks are the property of their
            respective owners.
          </small>
        </p>
      </div>
    </footer>
  );
};

const CTA = () => (
  <>
    <div id="wrap" className="cta container">
      <div className="cta-attract"></div>
      <Header shouldAnimate />
      <CTAScreen />
      <Footer />
    </div>
  </>
);

const Calculator = () => (
  <>
    <div className="attract"></div>
    <div id="wrap" className="calculator container">
      <Header />
      <CalculatorScreen />
    </div>
  </>
);

const Schedule = () => (
  <>
    <div className="schedule-attract"></div>
    <div id="wrap" className="schedule container-fluid">
      <Header />
      <ScheduleScreen />
    </div>
  </>
);

const List = () => (
  <div id="wrap" className="fullList container-fluid">
    <ViewListScreen />
  </div>
);

const GetListOnline = () => (
  <>
    <div className="send-attract"></div>
    <div id="wrap" className="getList container">
      <Header />
      <GetListOnlineScreen />
    </div>
  </>
);

const Email = () => (
  <>
    <div id="wrap" className="email container">
      <EmailScreen />
    </div>
  </>
);

const Finish = () => (
  <>
    <div id="wrap" className="cta container">
      <div className="cta-attract"></div>
      <Header />
      <FinishScreen />
    </div>
  </>
);

const Timeout = () => {
  const history = useHistory();

  const onIdle = () => {
    if (history.location.pathname !== "/") {
      console.log("idle timeout");
      history.push("/");
    }
  };

  return <IdleTimer onIdle={onIdle} timeout={IDLE_TIMEOUT_S * 1000} />;
};

export default function App() {
  React.useEffect(() => {
    if (DEBUG_ON_SCREEN) {
      attachUIConsole();
    }
  }, []);

  React.useEffect(() => {
    if (REACT_APP_MODE) {
      GetClientName().then((clientid) => {
        console.log("client id from kiosk pro:", clientid);
        startGoogleAnalytics(clientid);
      });
    } else {
      startGoogleAnalytics();
    }
  }, []);

  const startGoogleAnalytics = (clientId = null) => {
    ReactGA.initialize(GA_ANALYTICS_ID, { debug: DEBUG_ANALYTICS });
    const appName = REACT_APP_MODE
      ? "Binge Calculator Kiosk"
      : "Binge Calculator Web";
    ReactGA.set({ appName: appName });
    if (clientId) {
      ReactGA.set({ hostname: clientId });
    }
  };

  return (
    <>
      <Router>
        <Styled.Container>
          <Route exact={true} path="/" component={CTA} />
          <Route path="/Calculator" component={Calculator} />
          <Route path="/Schedule" component={Schedule} />
          <Route path="/ViewList" component={List} />
          <Route path="/GetList" component={GetListOnline} />
          <Route path="/Email" component={Email} />
          <Route path="/Finish" component={Finish} />
          <Route path="/u/:shortUrlId" component={ShortURLHandler} />
        </Styled.Container>
        <Timeout />
      </Router>
    </>
  );
}
