import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
import useTitle from "../hooks/useTitle";

export default function EmailScreen() {
  const [status, setStatus] = useState();
  const [email, setEmail] = useState("");
  useTitle("Send Email");
  const history = useHistory();
  const [age, setAge] = useState(false);
  const toggleAge = () => setAge((value) => !value);

  useEffect(() => {
    goToTop();
  }, []);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const sendEmail = async (event) => {
    event.preventDefault();
    if (!email) {
      setStatus("empty");
      return;
    }

    const validEmailPattern =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailIsValid = validEmailPattern.test(String(email).toLowerCase());

    if (!emailIsValid) {
      setStatus("invalid");
      return;
    }

    let shortUrlId = "";

    let shortUrl = sessionStorage.getItem("shortUrl");

    if (!shortUrl) {
      setStatus("error");
      console.error("Could not find short URL.");
      return;
    }

    const splitShortUrl = shortUrl.split("/");
    shortUrlId = splitShortUrl[splitShortUrl.length - 1];

    if (!shortUrlId) {
      setStatus("error");
      console.error("Short URL ID is empty.");
      return;
    }

    setStatus("sending");
    try {
      const response = await axios.get(
        `https://gaoebcd2wd.execute-api.us-west-2.amazonaws.com/send/`,
        { params: { urlId: shortUrlId, email } }
      );
      if (response.status === 200) {
        setStatus("success");
        history.push(`/Finish`);
        return;
      }

      setStatus("error");
      console.error(
        "Couldn't send email. Response status is not 200.",
        response.status
      );
      return;
    } catch (error) {
      setStatus("error");
      console.error("Couldn't send email.", error);
      return;
    }
  };

  const goHome = () => {
    history.push(`/`);
  };

  return (
    <>
      <div
        id="Email"
        className="email-main main row align-items-center justify-content-center"
      >
        <div className="col-12 col-md-9">
          <div className="row text-center">
            <div className="col-12">
              <h1 className="fancy">Enter your email address</h1>
              <form
                className="emailForm"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <input
                  name="email"
                  type="email"
                  className="emailInput"
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
                <br />
                <h3>By checking the following...</h3>
                <label className="row align-items-start justify-content-center">
                  <input
                    type="checkbox"
                    name="age-check"
                    className="ageCheck cm-checkmark col-auto"
                    checked={age}
                    onChange={toggleAge}
                  />
                  <span className="col">
                    I confirm that I am 18 years of age or older and allow AT&T
                    to collect my data for this activation{" "}
                    <strong>(required)</strong>
                  </span>
                </label>
                <br />
                <label className="row align-items-start justify-content-center">
                  <input
                    type="checkbox"
                    name="email-check"
                    className="emailCheck cm-checkmark col-auto"
                  />
                  <span className="col">
                    I confirm that I would like to receive promotional emails
                    from AT&T <strong>(optional)</strong>
                  </span>
                </label>
                <br />
                <button
                  onClick={sendEmail}
                  type="submit"
                  className="btn btn-small fancy-black"
                  disabled={!age === true}
                >
                  Send my schedule
                </button>
              </form>
            </div>
          </div>
          <br />
          <div className="row text-center">
            <div className="col-12">
              {status === "sending"
                ? "Sending email..."
                : status === "error"
                ? "Oops! Your schedule could not be sent."
                : status === "empty"
                ? "Please enter an email address."
                : status === "invalid"
                ? "Please enter a valid email address."
                : ""}
            </div>
          </div>
        </div>
        <button type="button" className="btn-link exit" onClick={goHome}>
          EXIT
        </button>
      </div>
    </>
  );
}
