import { useState, useEffect } from 'react';
import ReactGA from 'react-ga'

export default (initTitle) => {
  const [title, setTitle] = useState(initTitle);

  useEffect(() => {
    document.title = title;
    ReactGA.pageview(title);      
  }, [title])

  return [title, setTitle];
}