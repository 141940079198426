import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import useTitle from "../hooks/useTitle";
import useShortURL from "../hooks/useShortURL";
import { QRCode } from "react-qr-svg";

export default function GetListOnlineScreen() {
  useTitle("Email/Link");
  const history = useHistory();
  const { createShortUrl } = useShortURL();

  useEffect(() => {
    goToTop();
    getList();
  });

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const goHome = () => {
    history.push(`/`);
  };

  const getList = async () => {
    const shortUrl = sessionStorage.getItem("shortUrl");
    if (!shortUrl) {
      const { hash } = window.location;
      const rebuiltUrl = window.location.href.replace(hash, "#/Schedule");
      const shortUrl = await createShortUrl(rebuiltUrl);
      sessionStorage.setItem("shortUrl", shortUrl);
    }
  };

  return (
    <>
      <div
        id="GetList"
        className="main row align-items-center justify-content-center"
      >
        <div className="col-12 col-md-9">
          <div className="row text-center">
            <div className="col-12">
              <Link to={"/Email"} className="btn btn-xlarge fancy-black">
                Email my list
              </Link>
            </div>
            <div className="col-12 my-5">
              <h3>OR</h3>
            </div>
            <div className="col-12">
              <QRCode
                bgColor="#00b1e7"
                fgColor="#fff"
                level="Q"
                style={{ width: 256 }}
                value={window.location.href
                  .replace("localhost:3000", "binge.fanyourway.com")
                  .replace("#/GetList", "#/Schedule")}
              />
            </div>
          </div>
        </div>
        <button type="button" className="btn-link exit" onClick={goHome}>
          EXIT
        </button>
      </div>
    </>
  );
}
