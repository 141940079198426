import React from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import useTitle from "../hooks/useTitle";
import IdleTimer from "react-idle-timer";

export default function FinishScreen() {
  useTitle("Success");

  React.useEffect(() => {
    goToTop();
    ReactGA.event({
      category: "Interaction",
      action: "Binge Email Sent",
    });
  }, []);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const history = useHistory();

  const goHome = () => {
    let path = `/`;
    history.push(path);
  };

  const onIdle = () => {
    if (history.location.pathname !== "/") {
      console.log("finale timeout");
      history.push("/");
    }
  };

  return (
    <>
      <div id="CTA" className="main row align-items-end justify-content-center">
        <div className="col-12 col-md-9">
          <div className="row text-center">
            <div className="col-12">
              <h3 className="mb-5">
                Your watchlist was sent successfully.
                <br />
                Happy Holidays!
              </h3>
            </div>
            <div className="col-12 mb-3">
              <button onClick={goHome} className="btn btn-xlarge fancy-black">
                Exit
              </button>
            </div>
          </div>
        </div>
      </div>
      <IdleTimer onIdle={onIdle} timeout={5000} />
    </>
  );
}
