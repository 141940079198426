let clientId = 'NoClient'

window._sysinfo_clientId = function (kioskId) {
  clientId = kioskId
}

/**
 * Request the system's client name.
 *
 * @return {Promise<string>}
 */
export function GetClientName () {
  return new Promise((resolve, reject) => {
    window.kp_requestKioskId('_sysinfo_clientId')

    // Give KioskPro a chance to respond to the API request asynchronously.
    setTimeout(() => {
      resolve(clientId)
    }, 500)
  })
}
